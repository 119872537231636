<template>
  <common-trs-card class="pa-4">
    <v-container class="pa-0">
      <validation-observer
        ref="observer"
        v-slot="{ valid }"
      >
        <v-card-text class="pa-0">
          <v-form>
            <v-row no-gutters>
              <v-col cols="12">
                <validation-provider
                  v-slot="{ errors }"
                  :rules="{ required: true, email: true }"
                  :name="'Email'"
                >
                  <common-input-text-field
                    v-model="internalValue.username"
                    :error-messages="errors"
                    label="Email"
                  />
                </validation-provider>
              </v-col>
              <v-col cols="12">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  :name="'First Name'"
                >
                  <common-input-text-field
                    v-model="internalValue.first_name"
                    :error-messages="errors"
                    label="First Name"
                  />
                </validation-provider>
              </v-col>
              <v-col cols="12">
                <common-input-text-field
                  v-model="internalValue.middle_name"
                  label="Middle Name (Optional)"
                />
              </v-col>
              <v-col cols="12">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  :name="'Last Name'"
                >
                  <common-input-text-field
                    v-model="internalValue.last_name"
                    :error-messages="errors"
                    label="Last Name"
                  />
                </validation-provider>
              </v-col>
              <v-col cols="12">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  :name="'Role'"
                >
                  <common-input-select
                    v-model="internalValue.role_name"
                    label="Role Name"
                    placeholder="Select role name"
                    :items="allRoles"
                    item-text="role_display_name"
                    item-value="role_name"
                    :error-messages="errors"
                    :hide-details="errors.length === 0"
                    clearable
                  />
                </validation-provider>
              </v-col>
              <v-row>
                <v-col>
                  <h4 class="trsText--text text-body-2 text--light-1 font-weight-medium">
                    Is this Issuer an existing user of Cap Table ?
                  </h4>
                  <validation-provider
                    :name="'Existing Captable User'"
                    rules="required"
                  >
                    <v-radio-group
                      v-model="internalValue.is_existing_captable_user"
                      @change="formToggle"
                    >
                      <v-radio
                        label="Yes"
                        color="primary"
                        :value="true"
                      />
                      <v-radio
                        label="No"
                        color="primary"
                        :value="false"
                      />
                    </v-radio-group>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-col cols="12">
                <div v-if="showCaptableIdForm">
                  <h4 class="trsText--text text-body-2 text--light-1 font-weight-medium">
                    Please add the below detail(s)
                  </h4>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="'Captable ID'"
                    :rules="{ required: true, max: 255 }"
                  >
                    <common-input-text-field
                      v-model="internalValue.org_id"
                      label="Cap Table ID"
                      color="primary"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </div>
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  :name="'Organization Name'"
                >
                  <common-input-text-field
                    v-model="internalValue.org_name"
                    label="Organization Name"
                    color="primary"
                    :error-messages="errors"
                  />
                </validation-provider>
                <div v-if="showCaptableForm">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="'Captable ID'"
                    :rules="{ required: true, max: 255 }"
                  >
                    <common-input-text-field
                      v-model="uuid"
                      label="New Cap Table ID"
                      color="primary"
                      readonly
                      :error-messages="errors"
                    />
                  </validation-provider>
                  <common-trs-btn
                    type="primary"
                    class="mb-3"
                    :loading="loading"
                    @click="generateUUID"
                  >
                    Generate New Cap Table ID
                  </common-trs-btn>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-0 pt-3 pb-0">
          <v-spacer />
          <common-trs-btn
            type="tertiary"
            class="mr-3"
            @click="close"
          >
            Close
          </common-trs-btn>
          <common-trs-btn
            type="primary"
            :disabled="!valid"
            :loading="loading"
            @click="save"
          >
            Save
          </common-trs-btn>
        </v-card-actions>
      </validation-observer>
    </v-container>
  </common-trs-card>
</template>

<script>
  import { uuid } from 'vue-uuid'
  import * as tappAdminService from '@/services/tApp-admin-users'
  import * as captableAdminService from '@/services/captable-admin-service'
  import * as captableService from '@/components/common/captable/services/captable'

  export default {
    name: 'UsersForm',

    components: {
    },

    props: {
      value: {
        type: Object,
        default: () => {},
      },
    },

    data: () => ({
      loading: false,
      showCaptableIdForm: false,
      showCaptableForm: false,
      uuid: '',
      allRoles: [],
    }),

    computed: {
      internalValue: {
        get () {
          return this.value
        },
        set (val) {
          this.$emit('input', val)
        },
      },
    },

    mounted () {
      this.getCaptableRoles()
    },

    methods: {
      async generateUUID () {
        this.loading = true
        try {
          const currentUuid = uuid.v4()
          const isExistingOrg = await captableService.checkIfOrganizationExist(currentUuid)
          if (!isExistingOrg) {
            const today = new Date()
            await captableService.createNewOrg(this.internalValue.org_name, currentUuid, today)
            this.uuid = currentUuid
          } else {
            this.generateUUID()
          }
          this.loading = false
        } catch (e) {
          this.loading = false
        }
      },
      close () {
        this.$router.push({ name: 'Users.List' })
      },
      async getCaptableRoles () {
        try {
          this.loading = true
          const resp = await captableAdminService.getCaptableRoleList()
          this.allRoles = resp.results
        } catch (err) {
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Failed to load roles list',
          })
        }
        this.loading = false
      },
      async save () {
        this.loading = true
        const data = this.getFormData()
        try {
          const response = await tappAdminService.addCaptableUser(data)
          if (response && Object.keys(response).length > 0) {
            this.$store.commit('app/showMessage', { text: 'Successfully added User ' })
            this.$router.push({ name: 'Users.List' })
          }
        } catch (error) {
          this.$store.dispatch('app/handleError', {
            error,
            message: 'Failed to add Cap Table User',
          })
        }
        this.loading = false
      },
      async formToggle () {
        if (this.internalValue.is_existing_captable_user === undefined) return
        this.showCaptableIdForm = this.internalValue.is_existing_captable_user
        this.showCaptableForm = !this.internalValue.is_existing_captable_user
      },
      getFormData () {
        return {
          first_name: this.internalValue.first_name,
          middle_name: this.internalValue.middle_name,
          last_name: this.internalValue.last_name,
          org_id: this.internalValue.org_id ? this.internalValue.org_id : this.uuid,
          org_name: this.internalValue.org_name,
          username: this.internalValue.username,
          role_name: this.internalValue.role_name,
        }
      },
    },
  }
</script>

<style lang="sass">
</style>
