import { api } from './trellis-api'
import { composeGetQuery } from './utils'

export const getCaptableUsers = async (options, apiOpts) => {
  const params = composeGetQuery(options)
  const apiResponse = await api.get('captable-admin-user', { params }, apiOpts)
  return apiResponse.data
}

export const addCaptableUser = async (params, apiOpts) => {
  const apiResponse = await api.post('captable-admin-user', params, apiOpts)
  return apiResponse.data
}

export const ssoLogin = async (token, apiOpts) => {
  const apiResponse = await api.post(`sso-login?token=${token}`, apiOpts)
  return apiResponse.data
}
