var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('common-trs-card',{staticClass:"pa-4"},[_c('v-container',{staticClass:"pa-0"},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('v-card-text',{staticClass:"pa-0"},[_c('v-form',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":{ required: true, email: true },"name":'Email'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-text-field',{attrs:{"error-messages":errors,"label":"Email"},model:{value:(_vm.internalValue.username),callback:function ($$v) {_vm.$set(_vm.internalValue, "username", $$v)},expression:"internalValue.username"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required","name":'First Name'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-text-field',{attrs:{"error-messages":errors,"label":"First Name"},model:{value:(_vm.internalValue.first_name),callback:function ($$v) {_vm.$set(_vm.internalValue, "first_name", $$v)},expression:"internalValue.first_name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('common-input-text-field',{attrs:{"label":"Middle Name (Optional)"},model:{value:(_vm.internalValue.middle_name),callback:function ($$v) {_vm.$set(_vm.internalValue, "middle_name", $$v)},expression:"internalValue.middle_name"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required","name":'Last Name'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-text-field',{attrs:{"error-messages":errors,"label":"Last Name"},model:{value:(_vm.internalValue.last_name),callback:function ($$v) {_vm.$set(_vm.internalValue, "last_name", $$v)},expression:"internalValue.last_name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required","name":'Role'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-select',{attrs:{"label":"Role Name","placeholder":"Select role name","items":_vm.allRoles,"item-text":"role_display_name","item-value":"role_name","error-messages":errors,"hide-details":errors.length === 0,"clearable":""},model:{value:(_vm.internalValue.role_name),callback:function ($$v) {_vm.$set(_vm.internalValue, "role_name", $$v)},expression:"internalValue.role_name"}})]}}],null,true)})],1),_c('v-row',[_c('v-col',[_c('h4',{staticClass:"trsText--text text-body-2 text--light-1 font-weight-medium"},[_vm._v(" Is this Issuer an existing user of Cap Table ? ")]),_c('validation-provider',{attrs:{"name":'Existing Captable User',"rules":"required"}},[_c('v-radio-group',{on:{"change":_vm.formToggle},model:{value:(_vm.internalValue.is_existing_captable_user),callback:function ($$v) {_vm.$set(_vm.internalValue, "is_existing_captable_user", $$v)},expression:"internalValue.is_existing_captable_user"}},[_c('v-radio',{attrs:{"label":"Yes","color":"primary","value":true}}),_c('v-radio',{attrs:{"label":"No","color":"primary","value":false}})],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[(_vm.showCaptableIdForm)?_c('div',[_c('h4',{staticClass:"trsText--text text-body-2 text--light-1 font-weight-medium"},[_vm._v(" Please add the below detail(s) ")]),_c('validation-provider',{attrs:{"name":'Captable ID',"rules":{ required: true, max: 255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-text-field',{attrs:{"label":"Cap Table ID","color":"primary","error-messages":errors},model:{value:(_vm.internalValue.org_id),callback:function ($$v) {_vm.$set(_vm.internalValue, "org_id", $$v)},expression:"internalValue.org_id"}})]}}],null,true)})],1):_vm._e(),_c('validation-provider',{attrs:{"rules":"required","name":'Organization Name'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-text-field',{attrs:{"label":"Organization Name","color":"primary","error-messages":errors},model:{value:(_vm.internalValue.org_name),callback:function ($$v) {_vm.$set(_vm.internalValue, "org_name", $$v)},expression:"internalValue.org_name"}})]}}],null,true)}),(_vm.showCaptableForm)?_c('div',[_c('validation-provider',{attrs:{"name":'Captable ID',"rules":{ required: true, max: 255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-text-field',{attrs:{"label":"New Cap Table ID","color":"primary","readonly":"","error-messages":errors},model:{value:(_vm.uuid),callback:function ($$v) {_vm.uuid=$$v},expression:"uuid"}})]}}],null,true)}),_c('common-trs-btn',{staticClass:"mb-3",attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.generateUUID}},[_vm._v(" Generate New Cap Table ID ")])],1):_vm._e()],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"px-0 pt-3 pb-0"},[_c('v-spacer'),_c('common-trs-btn',{staticClass:"mr-3",attrs:{"type":"tertiary"},on:{"click":_vm.close}},[_vm._v(" Close ")]),_c('common-trs-btn',{attrs:{"type":"primary","disabled":!valid,"loading":_vm.loading},on:{"click":_vm.save}},[_vm._v(" Save ")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }